<template>
  <slot />
</template>

<script>
export default {
  props: {
    mapbox: Object,
    map: Object
  },

  provide () {
    return {
      mapbox: this.mapbox,
      map: this.map
    }
  }
}
</script>
