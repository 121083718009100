<template>
  <figure class="thumbnail square rounded" :style="figureStyles">
    <img v-if="species.thumbnailUrl" :src="species.thumbnailUrl" :alt="altDescription" />
  </figure>
</template>

<script>
import Color from 'color'

export default {
  props: {
    species: {
      type: Object,
      required: true
    }
  },

  computed: {
    altDescription () {
      return `Photo of ${this.species.commonName} (${this.species.scientificName})`
    },

    figureStyles () {
      return {
        borderColor: this.species.color,
        backgroundColor: Color(this.species.color).alpha(0.75)
      }
    }
  }
}
</script>
