<template>

</template>

<script>
import DefaultSpeciesMarker from '@/images/markers/species.png'

import { render, h } from 'vue'

function generateSpeciesMarker(image, color) {
  const canvas = document.createElement('canvas')
  canvas.width = image.width
  canvas.height = image.height

  const ctx = canvas.getContext('2d')

  const radius = image.width / 2
  const borderWidth = 12

  ctx.lineWidth = borderWidth
  ctx.strokeStyle = color

  ctx.ellipse(radius, radius, radius - borderWidth / 2, radius - borderWidth / 2, 0, 0, Math.PI * 2)
  ctx.clip()
  ctx.drawImage(image, 0, 0)
  ctx.stroke()

  return ctx.getImageData(0, 0, image.width, image.height)
}

export default {
  inject: ['mapbox', 'map'],

  mounted () {
    this.map.loadImage(DefaultSpeciesMarker, (error, image) => {
      if (!error) { this.defaultSpeciesMarker = image }
    })

    this.map.on('styleimagemissing', (e) => {
      const prefix = 'species-'
      if (!e.id.includes(prefix)) return

      const [id, color] = e.id.replace(prefix, '').split('-')

      const defaultMarker = generateSpeciesMarker(this.defaultSpeciesMarker, color)
      this.map.addImage(e.id, defaultMarker)

      const thumbnailUrl = `https://app.birdweather.com/species/${id}.png`
      this.map.loadImage(thumbnailUrl, (error, image) => {
        if (!error) {
          const marker = generateSpeciesMarker(image, color)
          this.map.updateImage(e.id, marker)
          this.map.triggerRepaint()
        }
      })
    })
  },

  methods: {
    createMarker (feature) {
      const el = document.createElement('div')
      el.style.width = '40px'
      el.style.height = '40px'

      const thumbnailUrl = `https://app.birdweather.com/species/${feature.properties.speciesId}.png`
      el.style.backgroundImage = `url(${thumbnailUrl})`
      el.style.backgroundSize = 'cover'

      el.style.border = '3px solid'
      el.style.borderRadius = '50%'
      el.style.borderColor = feature.properties.color

      return new this.mapbox.Marker(el).setLngLat(feature.geometry.coordinates)
    }
  }
}
</script>
