<template>
  <div class="toast-container top-0 start-0 p-3">
    <div ref="toast" class="toast show" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="toast-header">
        <small>{{ status }}</small>
      </div>

      <div class="toast-body">
        <div class="progress">
          <div class="progress-bar" :style="progressBarStyles" :class="progressBarClasses" role="progressbar" :aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      default: 'Loading...'
    },

    progress: {
      type: Number
    },

    indeterminate: {
      type: Boolean,
      default: false
    },

    animate: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    progressBarClasses () {
      const classes = ['progress-bar-striped']

      if (this.animate) {
        classes.push('progress-bar-animated')
      }

      if (this.indeterminate || this.progress < 100) {
        classes.push('bg-info')
      } else {
        classes.push('bg-success')
      }

      return classes.join(' ')
    },

    progressBarWidth () {
      if (this.indeterminate) {
        return '100%'
      } else if (this.progress) {
        return `${this.progress}%`
      } else {
        return '0%'
      }
    },

    progressBarStyles () {
      return {
        '--bs-progress-bar-transition': 'width .1s ease',
        width: this.progressBarWidth
      }
    }
  }
}
</script>
