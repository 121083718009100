export const TIMEZONES = {
  'Africa/Abidjan': [7.4512, -5.5473],
  'Africa/Accra': [7.8571, -0.9938],
  'Africa/Addis_Ababa': [9.1458, 40.49],
  'Africa/Algiers': [28.1322, 1.6642],
  'Africa/Asmara': [15.2129, 39.8668],
  'Africa/Bamako': [17.5745, -3.9864],
  'Africa/Bangui': [6.6034, 20.9411],
  'Africa/Banjul': [13.4432, -15.4133],
  'Africa/Bissau': [11.6688, -15.2647],
  'Africa/Blantyre': [-13.249, 34.2945],
  'Africa/Brazzaville': [-0.718, 14.8242],
  'Africa/Bujumbura': [-3.3895, 29.9253],
  'Africa/Cairo': [26.7791, 30.8827],
  'Africa/Casablanca': [31.8389, -7.216],
  'Africa/Ceuta': [35.5444, -3.9203],
  'Africa/Conakry': [9.9331, -11.603],
  'Africa/Dakar': [14.4647, -14.5661],
  'Africa/Dar_es_Salaam': [-6.3734, 34.9927],
  'Africa/Djibouti': [11.8536, 42.7146],
  'Africa/Douala': [7.3662, 12.2869],
  'Africa/El_Aaiun': [24.1118, -13.008],
  'Africa/Freetown': [8.3775, -11.886],
  'Africa/Gaborone': [-22.3421, 24.687],
  'Africa/Harare': [-19.0169, 29.1529],
  'Africa/Johannesburg': [-54.0625, 16.395],
  'Africa/Juba': [7.8627, 29.6984],
  'Africa/Kampala': [1.3759, 32.2869],
  'Africa/Khartoum': [15.4551, 30.4361],
  'Africa/Kigali': [-1.9436, 29.8804],
  'Africa/Kinshasa': [-1.4902, 18.2258],
  'Africa/Lagos': [8.9774, 8.6775],
  'Africa/Libreville': [-0.891, 11.5198],
  'Africa/Lome': [8.533, 0.8324],
  'Africa/Luanda': [-11.2135, 17.7745],
  'Africa/Lubumbashi': [-4.0335, 25.4898],
  'Africa/Lusaka': [-13.1756, 27.8545],
  'Africa/Malabo': [1.1579, 8.3886],
  'Africa/Maputo': [-18.6231, 35.6337],
  'Africa/Maseru': [-29.624, 28.2336],
  'Africa/Mbabane': [-26.5182, 31.4629],
  'Africa/Mogadishu': [5.1929, 46.3035],
  'Africa/Monrovia': [6.3538, -9.4877],
  'Africa/Nairobi': [-0.1397, 37.9082],
  'Africa/Ndjamena': [15.4463, 18.7368],
  'Africa/Niamey': [17.6054, 8.0829],
  'Africa/Nouakchott': [21.018, -10.9507],
  'Africa/Ouagadougou': [12.2473, -1.5521],
  'Africa/Porto-Novo': [9.2246, 2.3109],
  'Africa/Sao_Tome': [0.8562, 6.9656],
  'Africa/Tripoli': [26.4273, 17.3841],
  'Africa/Tunis': [33.9952, 9.7011],
  'Africa/Windhoek': [-22.9665, 18.3949],
  'America/Adak': [52.161, 0.0],
  'America/Anchorage': [62.9698, -151.5],
  'America/Anguilla': [18.4283, -63.1759],
  'America/Antigua': [17.3432, -62.0008],
  'America/Araguaina': [-9.3175, -48.2193],
  'America/Argentina/Buenos_Aires': [-37.3384, -59.5483],
  'America/Argentina/Catamarca': [-35.5843, -67.752],
  'America/Argentina/Cordoba': [-28.7434, -59.7045],
  'America/Argentina/Jujuy': [-23.1973, -65.6818],
  'America/Argentina/La_Rioja': [-29.854, -67.5291],
  'America/Argentina/Mendoza': [-34.7872, -68.5308],
  'America/Argentina/Rio_Gallegos': [-49.2891, -69.481],
  'America/Argentina/Salta': [-32.0015, -67.1529],
  'America/Argentina/San_Juan': [-30.5078, -68.6155],
  'America/Argentina/San_Luis': [-33.9172, -66.1879],
  'America/Argentina/Tucuman': [-27.0361, -65.3299],
  'America/Argentina/Ushuaia': [-69.2613, -51.8049],
  'America/Aruba': [12.5429, -69.9654],
  'America/Asuncion': [-23.447, -58.4511],
  'America/Atikokan': [57.0678, -86.2084],
  'America/Bahia_Banderas': [20.8576, -105.4204],
  'America/Bahia': [-13.4629, -41.903],
  'America/Barbados': [13.19, -59.5355],
  'America/Belem': [-2.5824, -50.4104],
  'America/Belize': [17.1908, -88.2686],
  'America/Blanc-Sablon': [50.2567, -60.0563],
  'America/Boa_Vista': [1.8377, -61.8527],
  'America/Bogota': [5.9127, -74.4878],
  'America/Boise': [44.0129, -114.6386],
  'America/Cambridge_Bay': [72.1784, -104.8415],
  'America/Campo_Grande': [-20.6204, -54.5464],
  'America/Cancun': [19.8524, -87.895],
  'America/Caracas': [8.2817, -66.4479],
  'America/Cayenne': [4.0444, -53.0861],
  'America/Cayman': [19.5098, -80.5712],
  'America/Chicago': [37.6108, -94.8202],
  'America/Chihuahua': [28.3733, -106.3518],
  'America/Costa_Rica': [8.2588, -84.9006],
  'America/Creston': [49.407, -116.3884],
  'America/Cuiaba': [-12.6949, -55.9203],
  'America/Curacao': [12.1845, -68.9043],
  'America/Danmarkshavn': [74.3339, -20.362],
  'America/Dawson_Creek': [55.8985, -122.473],
  'America/Dawson': [64.9204, -139.5014],
  'America/Denver': [39.8153, -108.1551],
  'America/Detroit': [45.0011, -86.2707],
  'America/Dominica': [15.3973, -61.36],
  'America/Edmonton': [54.4986, -114.413],
  'America/Eirunepe': [-6.8339, -70.6785],
  'America/El_Salvador': [13.7135, -88.9071],
  'America/Fort_Nelson': [59.0012, -123.9098],
  'America/Fortaleza': [-5.8438, -41.6778],
  'America/Glace_Bay': [46.2501, -61.0106],
  'America/Goose_Bay': [56.0257, -61.6027],
  'America/Grand_Turk': [21.5592, -71.7722],
  'America/Grenada': [12.1914, -61.5899],
  'America/Guadeloupe': [16.1792, -61.405],
  'America/Guatemala': [15.7256, -90.2431],
  'America/Guayaquil': [-1.7732, -78.2684],
  'America/Guyana': [4.8874, -58.939],
  'America/Halifax': [45.9887, -63.084],
  'America/Havana': [21.5546, -79.5435],
  'America/Hermosillo': [29.3956, -111.7387],
  'America/Indiana/Indianapolis': [40.0789, -86.2241],
  'America/Indiana/Knox': [41.3025, -86.6986],
  'America/Indiana/Marengo': [38.2638, -86.4636],
  'America/Indiana/Petersburg': [38.3922, -87.2683],
  'America/Indiana/Tell_City': [38.0529, -86.6221],
  'America/Indiana/Vevay': [38.8085, -84.994],
  'America/Indiana/Vincennes': [38.5584, -87.212],
  'America/Indiana/Winamac': [41.0411, -86.6987],
  'America/Inuvik': [68.7092, -128.294],
  'America/Iqaluit': [67.2464, -76.5031],
  'America/Jamaica': [17.6578, -77.1661],
  'America/Juneau': [58.331, -135.3196],
  'America/Kentucky/Louisville': [38.2827, -85.8681],
  'America/Kentucky/Monticello': [36.8006, -84.8199],
  'America/Kralendijk': [14.8375, -65.7059],
  'America/La_Paz': [-16.2836, -63.549],
  'America/Lima': [-10.1189, -76.6439],
  'America/Los_Angeles': [40.765, -119.5619],
  'America/Lower_Princes': [17.9351, -63.1086],
  'America/Maceio': [-10.1908, -36.6042],
  'America/Managua': [12.8704, -85.2621],
  'America/Manaus': [-3.794, -63.0721],
  'America/Marigot': [18.0434, -63.0625],
  'America/Martinique': [14.6506, -61.0189],
  'America/Matamoros': [27.4297, -100.2797],
  'America/Mazatlan': [24.3016, -109.8457],
  'America/Menominee': [45.9302, -88.8411],
  'America/Merida': [20.2979, -90.0177],
  'America/Metlakatla': [55.14, -131.484],
  'America/Mexico_City': [19.7569, -102.678],
  'America/Miquelon': [46.9579, -56.3003],
  'America/Moncton': [46.2602, -66.1912],
  'America/Monterrey': [25.6356, -102.2577],
  'America/Montevideo': [-32.9339, -55.7852],
  'America/Montserrat': [16.7452, -62.1931],
  'America/Nassau': [24.0898, -76.574],
  'America/New_York': [33.6648, -76.6881],
  'America/Nipigon': [50.9164, -84.9692],
  'America/Nome': [61.5584, -167.752],
  'America/Noronha': [-3.8344, -32.4374],
  'America/North_Dakota/Beulah': [47.2781, -101.7323],
  'America/North_Dakota/Center': [47.1385, -101.3216],
  'America/North_Dakota/New_Salem': [46.6291, -101.3201],
  'America/Nuuk': [71.6953, -39.3643],
  'America/Ojinaga': [30.1644, -106.1274],
  'America/Panama': [8.4521, -80.1052],
  'America/Pangnirtung': [70.751, -63.8069],
  'America/Paramaribo': [4.0282, -55.9571],
  'America/Phoenix': [34.167, -111.9319],
  'America/Port-au-Prince': [19.064, -73.4301],
  'America/Port_of_Spain': [10.718, -61.1864],
  'America/Porto_Velho': [-10.8309, -63.2902],
  'America/Puerto_Rico': [18.199, -66.6008],
  'America/Punta_Arenas': [-56.2911, -66.6747],
  'America/Rainy_River': [52.225, -91.8269],
  'America/Rankin_Inlet': [69.0204, -93.5007],
  'America/Recife': [-8.3781, -37.9933],
  'America/Regina': [54.4993, -105.6842],
  'America/Resolute': [75.1314, -94.9981],
  'America/Rio_Branco': [-9.1303, -70.3015],
  'America/Santarem': [-3.5449, -55.2529],
  'America/Santiago': [-33.355, -74.0344],
  'America/Santo_Domingo': [19.2816, -70.0838],
  'America/Sao_Paulo': [-23.1331, -48.555],
  'America/Scoresbysund': [71.3163, -22.8905],
  'America/Sitka': [56.3067, -133.4263],
  'America/St_Barthelemy': [17.9042, -62.8254],
  'America/St_Johns': [49.8894, -56.4],
  'America/St_Kitts': [17.2554, -62.6908],
  'America/St_Lucia': [13.8903, -60.9762],
  'America/St_Thomas': [18.0423, -64.7751],
  'America/St_Vincent': [13.0499, -61.2876],
  'America/Swift_Current': [54.4995, -108.1372],
  'America/Tegucigalpa': [15.3002, -85.7649],
  'America/Thule': [77.5955, -67.0625],
  'America/Thunder_Bay': [48.4205, -89.1005],
  'America/Tijuana': [30.3594, -115.5642],
  'America/Toronto': [52.3803, -73.1353],
  'America/Tortola': [18.5279, -64.5079],
  'America/Vancouver': [54.1132, -127.7481],
  'America/Whitehorse': [64.7398, -130.9056],
  'America/Winnipeg': [54.4991, -95.7526],
  'America/Yakutat': [59.5369, -139.25],
  'America/Yellowknife': [69.5641, -111.0003],
  'Antarctica/Casey': [-67.0, 108.5],
  'Antarctica/Davis': [-75.5, 81.75],
  'Antarctica/DumontDUrville': [-67.5, 140.0],
  'Antarctica/Macquarie': [-54.7395, 158.8335],
  'Antarctica/Mawson': [-75.0, 66.25],
  'Antarctica/McMurdo': [-77.5, 0.0],
  'Antarctica/Palmer': [-64.625, -64.65],
  'Antarctica/Rothera': [-76.0, -48.5],
  'Antarctica/Syowa': [-75.0, 40.0],
  'Antarctica/Troll': [-77.0, 12.5],
  'Antarctica/Vostok': [-75.0, 100.5],
  'Arctic/Longyearbyen': [75.8271, 12.5022],
  'Asia/Aden': [15.4543, 48.1736],
  'Asia/Almaty': [48.0054, 75.1329],
  'Asia/Amman': [31.2793, 37.0929],
  'Asia/Anadyr': [66.7066, 0.0],
  'Asia/Aqtau': [43.8833, 53.2762],
  'Asia/Aqtobe': [48.2474, 58.9539],
  'Asia/Ashgabat': [38.9633, 59.5128],
  'Asia/Atyrau': [47.6261, 51.7609],
  'Asia/Baghdad': [33.2197, 43.8265],
  'Asia/Bahrain': [26.1111, 50.5966],
  'Asia/Baku': [40.1717, 47.8862],
  'Asia/Bangkok': [14.5032, 102.7835],
  'Asia/Barnaul': [51.7613, 83.8774],
  'Asia/Beirut': [33.8737, 35.7538],
  'Asia/Bishkek': [41.2198, 74.7473],
  'Asia/Brunei': [4.5519, 114.7198],
  'Asia/Chita': [53.7958, 114.94],
  'Asia/Choibalsan': [47.5126, 115.5497],
  'Asia/Colombo': [7.877, 80.7385],
  'Asia/Damascus': [34.8149, 38.9233],
  'Asia/Dhaka': [23.507, 90.3442],
  'Asia/Dili': [-8.8269, 125.7876],
  'Asia/Dubai': [24.3917, 54.0093],
  'Asia/Dushanbe': [38.8581, 71.2437],
  'Asia/Famagusta': [35.4582, 33.7006],
  'Asia/Gaza': [31.4622, 34.3182],
  'Asia/Hebron': [31.9469, 35.2275],
  'Asia/Ho_Chi_Minh': [12.6288, 108.7258],
  'Asia/Hong_Kong': [22.3525, 114.1599],
  'Asia/Hovd': [46.7969, 93.4748],
  'Asia/Irkutsk': [57.1457, 107.3928],
  'Asia/Jakarta': [-1.3778, 105.628],
  'Asia/Jayapura': [-2.9685, 132.4716],
  'Asia/Jerusalem': [31.3945, 35.0813],
  'Asia/Kabul': [33.9342, 67.7038],
  'Asia/Kamchatka': [57.8121, 164.8671],
  'Asia/Karachi': [30.3118, 68.9967],
  'Asia/Kathmandu': [28.3974, 84.1301],
  'Asia/Khandyga': [62.5216, 136.2303],
  'Asia/Kolkata': [20.9535, 82.7535],
  'Asia/Krasnoyarsk': [65.6086, 94.8633],
  'Asia/Kuala_Lumpur': [3.9608, 101.844],
  'Asia/Kuching': [4.2603, 114.5607],
  'Asia/Kuwait': [29.3141, 47.7787],
  'Asia/Macau': [22.1469, 113.5792],
  'Asia/Magadan': [62.4883, 154.1121],
  'Asia/Makassar': [-2.7223, 120.8199],
  'Asia/Manila': [12.7688, 120.4513],
  'Asia/Muscat': [21.5838, 56.0273],
  'Asia/Nicosia': [34.9117, 33.1783],
  'Asia/Novokuznetsk': [54.4986, 86.9253],
  'Asia/Novosibirsk': [55.2646, 80.102],
  'Asia/Omsk': [56.0056, 73.331],
  'Asia/Oral': [49.8644, 50.5292],
  'Asia/Phnom_Penh': [12.0829, 104.9808],
  'Asia/Pontianak': [-1.4369, 111.9938],
  'Asia/Pyongyang': [40.3084, 127.508],
  'Asia/Qatar': [25.4269, 51.6028],
  'Asia/Qostanay': [51.4437, 64.0431],
  'Asia/Qyzylorda': [45.0752, 63.5455],
  'Asia/Riyadh': [24.2222, 45.0619],
  'Asia/Sakhalin': [50.1411, 142.9861],
  'Asia/Samarkand': [41.3863, 62.2021],
  'Asia/Seoul': [35.767, 128.2598],
  'Asia/Shanghai': [31.1996, 104.1376],
  'Asia/Singapore': [1.3218, 104.0687],
  'Asia/Srednekolymsk': [59.9248, 150.8907],
  'Asia/Taipei': [18.4051, 118.3282],
  'Asia/Tashkent': [40.9125, 69.8916],
  'Asia/Tbilisi': [42.3209, 43.3105],
  'Asia/Tehran': [32.3145, 53.6826],
  'Asia/Thimphu': [27.4744, 90.4359],
  'Asia/Tokyo': [32.9629, 138.4599],
  'Asia/Tomsk': [58.3208, 82.2179],
  'Asia/Ulaanbaatar': [46.8648, 104.7848],
  'Asia/Urumqi': [41.7574, 84.9437],
  'Asia/Ust-Nera': [54.3768, 145.987],
  'Asia/Vientiane': [18.2092, 103.8597],
  'Asia/Vladivostok': [59.7404, 144.4637],
  'Asia/Yakutsk': [61.8737, 120.5801],
  'Asia/Yangon': [18.9939, 96.671],
  'Asia/Yekaterinburg': [62.1086, 68.3878],
  'Asia/Yerevan': [40.0711, 45.0402],
  'Atlantic/Azores': [38.3261, -28.0443],
  'Atlantic/Bermuda': [32.3192, -64.7671],
  'Atlantic/Canary': [28.6727, -15.7475],
  'Atlantic/Cape_Verde': [16.0069, -24.014],
  'Atlantic/Faroe': [61.8679, -6.9699],
  'Atlantic/Madeira': [31.578, -16.5641],
  'Atlantic/Reykjavik': [65.2195, -18.9091],
  'Atlantic/South_Georgia': [-56.5171, -34.1008],
  'Atlantic/St_Helena': [-24.13, -10.0231],
  'Atlantic/Stanley': [-51.958, -59.5695],
  'Australia/Adelaide': [-32.0549, 135.0021],
  'Australia/Brisbane': [-19.1337, 145.8031],
  'Australia/Broken_Hill': [-31.9302, 141.5006],
  'Australia/Currie': [-39.8887, 143.9915],
  'Australia/Darwin': [-18.4256, 133.5139],
  'Australia/Eucla': [-31.9577, 127.251],
  'Australia/Hobart': [-41.5547, 146.3582],
  'Australia/Lindeman': [-20.2739, 149.0133],
  'Australia/Lord_Howe': [-31.637, 159.1581],
  'Australia/Melbourne': [-36.5897, 145.4976],
  'Australia/Perth': [-49.7539, 136.4329],
  'Australia/Sydney': [-32.8348, 147.3476],
  'Europe/Amsterdam': [52.2472, 5.1539],
  'Europe/Andorra': [42.5424, 1.6002],
  'Europe/Astrakhan': [47.063, 47.4236],
  'Europe/Athens': [38.2248, 24.4888],
  'Europe/Belgrade': [44.0238, 20.9103],
  'Europe/Berlin': [51.1847, 10.4541],
  'Europe/Bratislava': [48.6726, 19.6995],
  'Europe/Brussels': [50.5239, 4.3985],
  'Europe/Bucharest': [45.9421, 25.1537],
  'Europe/Budapest': [47.1612, 19.5058],
  'Europe/Busingen': [47.7001, 8.6883],
  'Europe/Chisinau': [46.9796, 28.3901],
  'Europe/Copenhagen': [56.2021, 11.6342],
  'Europe/Dublin': [53.429, -8.3358],
  'Europe/Gibraltar': [36.1069, -5.3387],
  'Europe/Guernsey': [49.5801, -2.5356],
  'Europe/Helsinki': [64.784, 25.8763],
  'Europe/Isle_of_Man': [54.1992, -4.568],
  'Europe/Istanbul': [39.0524, 35.2195],
  'Europe/Jersey': [49.1654, -2.1976],
  'Europe/Kaliningrad': [54.8512, 21.1455],
  'Europe/Kiev': [48.7164, 31.4342],
  'Europe/Kirov': [58.5641, 50.0969],
  'Europe/Lisbon': [39.4334, -8.012],
  'Europe/Ljubljana': [46.1491, 14.9862],
  'Europe/London': [55.3675, -5.9618],
  'Europe/Luxembourg': [49.8153, 6.1335],
  'Europe/Madrid': [39.8705, -2.4918],
  'Europe/Malta': [35.9442, 14.3796],
  'Europe/Mariehamn': [60.1654, 20.2145],
  'Europe/Minsk': [53.7175, 27.9706],
  'Europe/Monaco': [43.6342, 7.471],
  'Europe/Moscow': [61.6219, 48.1987],
  'Europe/Oslo': [64.572, 17.9245],
  'Europe/Paris': [46.2834, 2.1882],
  'Europe/Podgorica': [42.6541, 19.3863],
  'Europe/Prague': [49.8038, 15.475],
  'Europe/Riga': [56.8802, 24.4565],
  'Europe/Rome': [41.1906, 12.7059],
  'Europe/Samara': [55.1594, 51.1833],
  'Europe/San_Marino': [43.9429, 12.4597],
  'Europe/Sarajevo': [43.9159, 17.6762],
  'Europe/Saratov': [51.3103, 46.6682],
  'Europe/Simferopol': [45.2071, 34.4416],
  'Europe/Skopje': [41.6136, 21.7435],
  'Europe/Sofia': [42.7256, 25.6223],
  'Europe/Stockholm': [62.0966, 17.3854],
  'Europe/Tallinn': [58.7239, 24.7963],
  'Europe/Tirane': [41.153, 20.091],
  'Europe/Ulyanovsk': [53.7121, 48.0201],
  'Europe/Uzhgorod': [48.4974, 23.3823],
  'Europe/Vaduz': [47.1595, 9.5537],
  'Europe/Vatican': [41.9038, 12.4521],
  'Europe/Vienna': [47.6964, 13.3458],
  'Europe/Vilnius': [55.1736, 23.7456],
  'Europe/Volgograd': [49.3457, 44.2999],
  'Europe/Warsaw': [52.019, 19.1077],
  'Europe/Zagreb': [44.3658, 16.3289],
  'Europe/Zaporozhye': [47.1457, 35.6941],
  'Europe/Zurich': [46.8133, 8.2241],
  'Indian/Antananarivo': [-18.7585, 46.8204],
  'Indian/Chagos': [-6.3413, 71.8693],
  'Indian/Christmas': [-10.4915, 105.623],
  'Indian/Cocos': [-12.0165, 96.8733],
  'Indian/Comoro': [-11.893, 43.8853],
  'Indian/Kerguelen': [-43.9055, 63.8639],
  'Indian/Mahe': [-6.9885, 51.2484],
  'Indian/Maldives': [3.2016, 73.1628],
  'Indian/Mauritius': [-15.4315, 60.0488],
  'Indian/Mayotte': [-12.8262, 45.1254],
  'Indian/Reunion': [-21.1316, 55.5271],
  'Pacific/Apia': [-13.7577, -172.1011],
  'Pacific/Auckland': [-40.9259, 0.1526],
  'Pacific/Bougainville': [-5.0017, 156.8023],
  'Pacific/Chatham': [-43.9729, -176.3931],
  'Pacific/Chuuk': [7.687, 145.6235],
  'Pacific/Easter': [-27.1285, -109.3413],
  'Pacific/Efate': [-16.6671, 168.3927],
  'Pacific/Enderbury': [-3.7305, -172.6255],
  'Pacific/Fakaofo': [-8.9886, -171.8506],
  'Pacific/Fiji': [-17.1024, 0.0],
  'Pacific/Funafuti': [-8.2155, 0.0],
  'Pacific/Galapagos': [0.1357, -90.6227],
  'Pacific/Gambier': [-22.3344, -135.6056],
  'Pacific/Guadalcanal': [-9.0266, 162.8578],
  'Pacific/Guam': [13.4451, 144.7882],
  'Pacific/Honolulu': [21.3886, -164.4142],
  'Pacific/Kiritimati': [-3.373, -155.3013],
  'Pacific/Kosrae': [5.314, 162.9673],
  'Pacific/Kwajalein': [9.059, 167.2955],
  'Pacific/Majuro': [9.6235, 166.4843],
  'Pacific/Marquesas': [-9.2069, -139.6656],
  'Pacific/Midway': [28.296, -177.8247],
  'Pacific/Nauru': [-0.528, 166.9348],
  'Pacific/Niue': [-19.0542, -169.8621],
  'Pacific/Norfolk': [-29.0654, 167.9559],
  'Pacific/Noumea': [-20.4544, 165.1453],
  'Pacific/Pago_Pago': [-12.7102, -170.2538],
  'Pacific/Palau': [5.485, 132.92],
  'Pacific/Pitcairn': [-24.4982, -127.7613],
  'Pacific/Pohnpei': [4.5875, 157.4786],
  'Pacific/Port_Moresby': [-6.2065, 147.7226],
  'Pacific/Rarotonga': [-15.4375, -161.5973],
  'Pacific/Saipan': [17.332, 145.4733],
  'Pacific/Tahiti': [-20.9949, -145.5096],
  'Pacific/Tarawa': [0.3642, 173.1843],
  'Pacific/Tongatapu': [-19.7345, -176.4581],
  'Pacific/Wake': [19.2969, 166.6263],
  'Pacific/Wallis': [-13.773, -177.1532]
}

export function getUserTimeZone () {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export function locationForTimeZone (tz) {
  return TIMEZONES[tz]
}

export function locationForUserTimeZone () {
  return locationForTimeZone(getUserTimeZone())
}
