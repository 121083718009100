<template>
  <div class="position-absolute w-100 h-100" :class="zoomLevelClass">
    <div ref="map" class="position-absolute w-100 h-100"></div>

    <MapProvider v-if="map && mapbox" :map="map" :mapbox="mapbox">
      <slot />
    </MapProvider>
  </div>
</template>

<script>
import mapbox from 'mapbox-gl'

import MapProvider from './MapProvider.vue'

import { locationForUserTimeZone } from '@/lib/timezone'

const THEMES = {
  light: 'mapbox://styles/timsterc/clwu8goi601cc01rd4p4e5ji5',
  dark: 'mapbox://styles/timsterc/clwu8izeq014001ppak7h4jey'
}

export default {
  props: {
    center: {
      type: Array,
      default: () => locationForUserTimeZone().reverse()
    },

    zoom: {
      type: Number,
      default: 15
    },

    projection: {
      type: String,
      default: 'globe'
    },

    theme: {
      type: String,
      default: 'light'
    }
  },

  mounted () {
    this.createMap()
  },

  provide () {
    return {
      map: this.map,
      mapbox: this.mapbox
    }
  },

  data () {
    return {
      map: undefined,

      accessToken: 'pk.eyJ1IjoidGltc3RlcmMiLCJhIjoiY2t2cG13NW5xMTYxYTJwcXNjazFvdGNmdCJ9.PoKQIhxgPdiHMkOJktEKgg',
      optimizeForTerrain: false,

      initialZoom: this.zoom
    }
  },

  created () {
    this.mapbox = mapbox
  },

  methods: {
    async createMap () {
      try {
        mapbox.accessToken = this.accessToken

        this.map = new mapbox.Map({
          container: this.$refs.map,
          style: this.mapStyle,
          projection: this.projection,
          dragRotate: false,

          center: this.center,
          zoom: this.zoom
        })

        this.map.on('zoom', (e) => this.zooming(e))
      } catch (err) {
        console.log('Error creating map', err)
      }
    },

    zooming (e) {
      this.$emit('update:zoom', e.target.getZoom())
    },

    updateCenter (center) {
      this.$emit('update:bounds', this.map.getBounds())
      this.$emit('update:center', center)
    },

    updateZoom (zoom) {
      this.$emit('update:bounds', this.map.getBounds())
      this.$emit('update:zoom', zoom)
    },

    zoomTo (zoom, options) {
      this.map.zoomTo(zoom, options)
    },

    flyTo (coords, options = { zoom: this.initialZoom, speed: 0.7 }) {
      this.map.flyTo({
        center: coords,
        ...options
      })
    },

    lockPosition () {
      this.map.dragPan.disable()
      this.map.doubleClickZoom.disable()

      this.map.scrollZoom.disable()
      this.map.scrollZoom.enable({ around: 'center' })
    },

    unlockPosition () {
      this.map.dragPan.enable()
      this.map.doubleClickZoom.enable()

      this.map.scrollZoom.disable()
      this.map.scrollZoom.enable()
    }
  },

  computed: {
    zoomLevelClass () {
      if (this.zoom < 3) {
        return 'zoom-x-small'
      } else if (this.zoom < 5) {
        return 'zoom-small'
      }
    },

    mapStyle () {
      return THEMES[this.theme]
    }
  },

  watch: {
    theme () {
      this.map.setStyle(this.mapStyle)
    },

    projection () {
      this.map.setProjection(this.projection)
    }
  },

  components: {
    MapProvider
  }
}
</script>
